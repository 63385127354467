body {
  background: url(./img/tlo.svg) no-repeat center top #000000;
  background-size: 1280px auto;
  color: #fff;
}

a {
  text-decoration: none;
}

.App {
  max-width: 1024px;
  margin: 0 auto;
}

.mode-icon {
  max-height: 60px;
  /* float: right; */
  margin: 60px auto 0;
}

h2 sup {
  font-size: 60px;
}

.buttonImg {
  opacity: 0.6;
}

.buttonImg.active {
  opacity: 1;
}

.buttonImg.active span.line {
  text-decoration: underline;
  width: 50%;
  height: 2px;
  background: #ffffff;
  margin: 0 auto;
}

/* ----------- SWIPER ---------- */
.swiper {
  width: 60%;
}

.swiper-pagination-bullet {
  border: 2px solid #ffffff;
  padding: 5px;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 15px;
  opacity: 1;
}


.swiper-container {
  width: auto;
  background: transparent;
}

.swiper-slide {
  text-align: center;
  font-size: 36px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}
.swiper-slide.swiper-slide-visible.swiper-slide-prev,
.swiper-slide.swiper-slide-visible.swiper-slide-next {
  color: slategray;
}
.swiper-slide.swiper-slide-active {
  border-bottom: 2px solid rgba(255, 255, 255, 0.6);
  border-top: 2px solid rgba(255, 255, 255, 0.6);
  font-size: 56px;
}

#rok_budowy, #cena_energii {
  text-align: center;
}

.contactButton {
  border: 2px solid #283e82;
  padding: 15px 40px;
  width: 260px;
  border-radius: 60px;
  margin: 15px auto;
}

.contactButton a {
  color: #ffffff;
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.expenditurePumpImg {
  max-width: 80%;
  width: 100%;
  margin: 0;
}

.seasonBlock {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
}

.choicePumpImg {
  max-height: 250px;
  cursor: pointer; 
  display: block;
  object-fit: fill !important;
}

.choicePumpTxt {
  text-align: center; 
  display: block; 
  margin-top: 15px !important; 
  height: 20px;
}

.energyPrice {
  margin-top: 30px !important; 
  margin-right: 35px !important; 
  float: right; 
  max-width: 100px;
}

@media screen and (max-width: 1084px) {
  .App {
    padding: 0 20px;
    overflow: hidden;
  }
}

@media screen and (max-width:900px) {
  .buildingBlock {
    padding: 0 !important;
    max-width: 600px !important;
    width: 100%;
    margin: 0 auto !important;
  }

  .energyPrice {
    margin-top: 20px !important; 
    margin-right: 0 !important; 
    float: right; 
    max-width: 100px;
  }

  #rok_budowy { 
    margin-top: -10px;
  }
}

@media screen and (max-width:960px) {
  .mode-icon {
    max-height: 60px;
    /* float: right; */
    margin: 30px auto 0;
  }

  h2 sup {
    font-size: 40px;
  }

  .expenditureBlock {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }

  .seasonBlock {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
  }

  .choicePumpImg {
    max-height: 220px;
    cursor: pointer; 
    display: block;
    object-fit: fill !important;
  }
  
  .choicePumpTxt {
    text-align: center; 
    display: block; 
    margin-top: 15px; 
    height: 20px;
  }
}

@media screen and (max-width: 760px) {
  .expenditureBlock {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }

  .buildingBlock {
    max-width: 400px !important;
  }
}

@media screen and (max-width: 600px) {
  .mode-icon {
    max-height: 50px;
    /* float: right; */
    margin: 15px auto 0;
  }

  h2 sup {
    font-size: 30px;
  }

  .smallComfortBlock, .seasonBlock {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }

  .choicePumpImg {
    max-height: 150px;
    cursor: pointer; 
    display: block;
    object-fit: fill !important;
  }
  
  .choicePumpTxt {
    text-align: center; 
    display: block; 
    margin-top: 15px; 
    height: 20px;
    font-size: 12px;
  }

}

@media screen and (max-width: 400px) {
  .expenditureCaption {
    font-size: 12px !important;
  }

  .choicePumpTxt {
    text-align: center; 
    display: block; 
    margin-top: 15px; 
    height: 15px;
    font-size: 10px;
  }
}

